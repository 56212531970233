.login-page {
    height: 100vh;
    .logo {
        width: 221px;
        height: 60px;
        margin-bottom: 17px;
        background-image: url("../statics/assets/images/logo_retina.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .login-page-inner,
    .login-slider {
        height: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        .copyright {
            position: absolute;
            bottom: 0;
            width: 100%;
            font-size: 14px;
            line-height: 16px;
            color: #6e7370;
            padding: 16px 24px;
            text-align: center;
            a {
                color: #15cd72;
                &:hover {
                    color: #11a25a;
                }
            }
        }
    }

    .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .des-login {
            margin-bottom: 35px;
            text-align: center;
            strong {
                display: block;
            }
        }
    }

    .feature-login {
        text-align: left;
        padding: 0 15px;
        margin-top: 10px;
        ul {
            list-style-type: none;
            li {
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;
                padding: 5px 0;
                &::before {
                    content: "\2713";
                    margin-right: 12px;
                    color: #15cd72;
                    font-weight: bold;
                }
            }
        }
    }

    .Login-page-inner {
        background-color: #fff;
        width: 500px;
        max-width: 100%;
        min-height: 100vh;
        text-align: center;
        @media (max-width: 1439px) {
            width: 425px;
        }
    }
    .btn-google-login {
        background-color: #fff;
        outline: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        height: 40px;
        cursor: pointer;
        border: solid 1px #dee2e6;
        box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;

        .google-icon {
            width: 17px;
            margin-right: 10px;
        }

        .text {
            font-size: 12px;
            font-weight: 500;
            color: #000000;
            line-height: 1;
        }
    }
    .login-slider {
        width: 80%;
        margin: 0 auto;
        background-color: #f0f2f5;
        .ant-carousel {
            .slick-next {
                &::before {
                    content: "";
                }
            }
            .slick-prev {
                &::before {
                    content: "";
                }
            }
        }
        .slide-wrapper {
            img {
                width: 100%;
            }
            .slide-content {
                text-align: center;
            }
        }
        .slide-content {
            .slide-title {
                font-weight: 700;
                font-size: 36px;
                line-height: 44px;
                color: #2f3230;
                margin-bottom: 14px;
                overflow: hidden;
                -webkit-line-clamp: 2;
                max-height: 90px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
            .slide-decription {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #6e7370;
                margin-bottom: 30px;
                overflow: hidden;
                -webkit-line-clamp: 2;
                max-height: 50px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
            .slide-link a {
                margin-top: 20px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #2f3230;
                border-radius: 4px;
                display: inline-block;
                border: 1px solid #a9afab;
                padding: 16px 40px;
            }
        }
        .slick-dots li {
            display: none;
        }
    }
}
