.KolProfitContainer {
    h2 {
        font-size: 28px;
    }
    h3 {
        font-size: 18px;
    }
    p,
    span,
    label {
        font-size: 16px;
    }
    .affiliate-information {
        .input-link {
            width: 60%;
            input {
                width: calc(100% - 50px);
            }
        }
        .input-id {
            width: 37%;
        }
        .ant-btn-icon-only {
            height: 40px;
        }
    }
    .affiliate-detail-profit {
        .withdraw {
            &::before {
                content: "|";
                color: #dee2e6;
                margin: 0 20px;
            }
        }
        .btn-link {
            span {
                text-decoration: underline;
            }
        }
        .total,
        .withdraw-title {
            color: #65676b;
        }
        .total-value,
        .withdraw-value {
            color: #0f9d58;
            font-weight: 500;
            margin-left: 5px;
        }
        @media (max-width: 996px) {
            .withdraw {
                &::before {
                    margin: 0 10px;
                }
            }
        }
        @media (max-width: 576px) {
            .withdraw {
                &::before {
                    content: "";
                    margin: 0;
                }
            }
            .btn-link {
                padding: 0;
            }
        }
    }

    .kol-profit-table {
        width: 100%;
        overflow-x: auto;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .ant-table-thead {
            .ant-table-cell {
                color: #ffffff;
                background-color: #0f9d58;
            }
        }
        .ant-table-tbody {
            p {
                margin-bottom: 0;
            }
        }
    }
}
.history-table {
    .ant-table-thead {
        .ant-table-cell {
            color: #303238;
            background-color: #eaeef1;
        }
    }
    .ant-table-tbody {
        .inline-date {
            display: flex;
            .dot {
                margin: auto 5px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                border: 1px solid #dee2e6;
                background-color: #dee2e6;
            }
        }
    }
}

.history-table {
    padding-right: 10px;
    max-height: 600px;
    overflow: auto;
    .ant-table-content {
        .ant-table-thead > tr > th {
            font-weight: bold;
            &::before {
                display: none;
            }
        }
        .ant-table-tbody > tr > td {
            padding: 6px;
        }
    }
}
